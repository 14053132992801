import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../../components/Button";
import SectionHeading from "../../components/SectionHeading";
import ACTIONS from "../../constants/actions";
import { useReducerContext } from "../../contexts/ReducerContext";
import CompanyInfoSection from "./OrganizationSettingsSections/CompanyInfoSection";
import Accordion from "../../components/Accordion";
import { Company, Employer } from "../../types/api";
import { updateCompany } from "../../apis/company";
import { getCurrentUser } from "../../apis/auth";
import { updateEmployer } from "../../apis/employer";

interface EmployerOrganizationSettingsProps {}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 860px;
  gap: 20px;
`;

const LocalSectionHeading = styled(SectionHeading)`
  font-weight: 500;
  
  //These styles don't match the latest design but this is intentional
  //because the alignment of the headings in the settings pages are not consistent
  //When we update other setting pages, we will update the headings all together
  font-size: 32px;
  line-height: 46px;
  height: auto;
  margin-bottom: 20px;  
`;

const SaveButton = styled(Button)`
  min-width: 170px;
  margin-left: auto;
`;

const EmployerOrganizationSettings: FunctionComponent<
  EmployerOrganizationSettingsProps
> = () => {
  const { t } = useTranslation();
  const { dispatch } = useReducerContext();
  const [company, setCompany] = useState<Partial<Company>>({});
  const [employer, setEmployer] = useState<Partial<Employer>>({});
  const setCompanyData = (val: Partial<Company>) => setCompany(prev => ({ ...prev, ...val }));
  const setEmployerData = (val: Partial<Employer>) => setEmployer(prev => ({ ...prev, ...val }));

  const isThereMissingRequiredField = useMemo(() => { 
    const areRequiredFieldsFilled = [
      //企業情報/Company information section
      company?.jaName,
      company?.enName,
      company?.postalCode,
      company?.jaPrefecture,
      company?.enPrefecture,
      company?.jaCityWard,
      company?.enCityWard,
      company?.jaTown,
      company?.enTown,
      company?.jaAddressNumber,
      company?.enAddressNumber,
      company?.jaBuildingName,
      company?.enBuildingName,
      company?.phoneNumber,
    ].every(val => val);

    return !areRequiredFieldsFilled;
  }, [company]);

  const onClickSave = async () => {
    if (isThereMissingRequiredField) {
      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "warning",
          message: `
            <b>必須項目エラー</b><br />
            必須項目が全て入力・選択されていないため、保存できません。<br />
            必須項目を確認してください
          `,
        },
      });
      return;
    }

    try {
      dispatch({ type: ACTIONS.START_LOADING });

      //The update employer data will be included in the response of the update company API
      //await updateEmployer(company?.employer?.id!, employer);
      const updatedCompany = await updateCompany(company?.id!, company);
      dispatch({ 
        type: ACTIONS.SET_PROMPT, 
        payload: { 
          type: "success",
          message: "組織情報が更新されました",
        } 
      });
      dispatch({
        type: ACTIONS.UPDATE_COMPANY,
        payload: {
            company: updatedCompany,
        },
      });
    } catch (e) {
      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "warning",
          message: "エラーが発生しました",
        },
      })
    } finally {
      dispatch({
        type: ACTIONS.STOP_LOADING,
      });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const user = await getCurrentUser();
        setCompany(user.company!);
      } catch {
        //todo: error handling
      }
    })();
  }, [])

  return (
    <>
      <LocalSectionHeading>
        {t("core.settings.organization.title")}
      </LocalSectionHeading>
      <Content>
        <Accordion 
          allowContentOverflow
          headingText="企業情報"
          active
        >
          <CompanyInfoSection  
            company={company}
            employer={employer}
            onChangeCompany={setCompanyData}
            onChangeEmployer={setEmployerData}
          />
        </Accordion>

        <SaveButton 
          onClick={onClickSave}
        >
          {t("core.save")}
        </SaveButton>
      </Content>
    </>
  );
};

export default EmployerOrganizationSettings;
