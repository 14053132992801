import { FunctionComponent } from "react";
import {
  Column5,
  FieldCell,
  GridRow,
  GridRowsContainer,
  HeadingContainer,
  LabelCell,
  LocalMultiTextInputMiddle,
  LocalMultiTextInputSmall,
  LocalPostalCodeInput,
  LocalTextInput,
  LocalTextInputGrow,
  SectionHeadingLarge,
  TransValFieldGroup,
  TransValFieldGroupStartAligned,
  WarningNote,
} from "../commonStyle";
import Label from "../../../../core/components/Label";
import Text from "../../../../core/components/Text";
import { JobFormSectionProps } from "../type";
import { useTranslation } from "react-i18next";
import { isEmpty } from "../../../../core/utils";

interface CompanyInfoSectionProps extends JobFormSectionProps {}

const CompanyInfoSection: FunctionComponent<CompanyInfoSectionProps> = ({
  job,
  onChange,
}) => {
  const { t: tCore } = useTranslation("translation", { keyPrefix: "core" });

  return (
    <>
      <HeadingContainer>
        <SectionHeadingLarge>企業情報</SectionHeadingLarge>
        <WarningNote>
          ※この情報は、求職者へのプレビュー表示や雇用条件書作成時に使用します。
        </WarningNote>
      </HeadingContainer>

      <GridRowsContainer>
        {/* 会社/機関名） */}
        <GridRow>
          <LabelCell>
            <Label required>会社/機関名</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroupStartAligned>
              <Text>日</Text>
              <LocalMultiTextInputSmall
                disabled
                placeholder="日本工業株式会社"
                value={job?.ecEmployerJaOrganizationName ?? ""}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalMultiTextInputSmall
                placeholder="Nippon Kohgyo Co, Ltd."
                value={job?.ecEmployerEnOrganizationName ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerEnOrganizationName: val })}
                invalid={isEmpty(job?.ecEmployerEnOrganizationName)}
              />
            </TransValFieldGroupStartAligned>
          </FieldCell>
        </GridRow>

        {/* 郵便番号 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("postal_code")}</Label>
          </LabelCell>
          <FieldCell>
            <Column5>
              <LocalPostalCodeInput
                value={job?.ecEmployerPostalCode ?? ""}
                onTextChange={(val) => {
                  onChange?.({ ecEmployerPostalCode: val });
                }}
                onPostalCodeAddressIdChange={(val) =>
                  onChange?.({ ecEmployerPostalCodeAddressId: val })
                }
                onPrefectureChange={(val) => {
                  onChange?.({
                    ecEmployerJaPrefecture: val.jaPrefecture,
                    ecEmployerEnPrefecture: val.enPrefecture,
                  });
                }}
                onCityChange={(val) => {
                  onChange?.({
                    ecEmployerJaCityWard: val.jaCity,
                    ecEmployerEnCityWard: val.enCity,
                  });
                }}
                onTownChange={(val) => {
                  onChange?.({
                    ecEmployerJaTown: val.jaTown,
                    ecEmployerEnTown: val.enTown,
                  });
                }}
                error={isEmpty(job?.ecEmployerPostalCode)}
                suppressErrorMessage
              />
            </Column5>
          </FieldCell>
        </GridRow>

        {/* 都道府県 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("prefecture")}</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="東京都"
                value={job?.ecEmployerJaPrefecture ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerJaPrefecture: val })}
                error={isEmpty(job?.ecEmployerJaPrefecture)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="Tokyo"
                value={job?.ecEmployerEnPrefecture ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerEnPrefecture: val })}
                error={isEmpty(job?.ecEmployerEnPrefecture)}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 市区 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("city")}</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="港区"
                value={job?.ecEmployerJaCityWard ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerJaCityWard: val })}
                error={isEmpty(job?.ecEmployerJaCityWard)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="Minato-ku"
                value={job?.ecEmployerEnCityWard ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerEnCityWard: val })}
                error={isEmpty(job?.ecEmployerEnCityWard)}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 町村 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("town_name")}</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="海岸"
                value={job?.ecEmployerJaTown ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerJaTown: val })}
                error={isEmpty(job?.ecEmployerJaTown)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="Kaigan"
                value={job?.ecEmployerEnTown ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerEnTown: val })}
                error={isEmpty(job?.ecEmployerEnTown)}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 番地 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("locality")}</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="１丁目９−１８"
                value={job?.ecEmployerJaAddressNumber ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerJaAddressNumber: val })}
                error={isEmpty(job?.ecEmployerJaAddressNumber)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="1-9-18"
                value={job?.ecEmployerEnAddressNumber ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerEnAddressNumber: val })}
                error={isEmpty(job?.ecEmployerEnAddressNumber)}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 建物名等 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("building_etc")}</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroupStartAligned>
              <Text>日</Text>
              <LocalMultiTextInputMiddle
                placeholder="國際浜松町ビル 2階"
                value={job?.ecEmployerJaBuilding ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerJaBuilding: val })}
                invalid={isEmpty(job?.ecEmployerJaBuilding)}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalMultiTextInputMiddle
                placeholder="International Hamamatsucho Building 2F"
                value={job?.ecEmployerEnBuilding ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerEnBuilding: val })}
                invalid={isEmpty(job?.ecEmployerEnBuilding)}
              />
            </TransValFieldGroupStartAligned>
          </FieldCell>
        </GridRow>

        {/* 電話番号 */}
        <GridRow>
          <LabelCell>
            <Label required>{tCore("phone_number")}</Label>
          </LabelCell>
          <FieldCell>
            <LocalTextInput
              placeholder="03-1234-5678"
              value={job?.ecEmployerPhoneNumber ?? ""}
              onTextChange={(val) =>
                onChange?.({ ecEmployerPhoneNumber: val })
              }
              error={isEmpty(job?.ecEmployerPhoneNumber)}
            />
          </FieldCell>
        </GridRow>

        {/* 代表者名 */}
        <GridRow>
          <LabelCell>
            <Label>代表者名</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="特定太郎"
                value={job?.ecEmployerJaRepresentativeName ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerJaRepresentativeName: val })}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="Taro Tokutei"
                value={job?.ecEmployerEnRepresentativeName ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerEnRepresentativeName: val })}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>

        {/* 代表者役職 */}
        <GridRow>
          <LabelCell>
            <Label>代表者役職</Label>
          </LabelCell>
          <FieldCell>
            <TransValFieldGroup>
              <Text>日</Text>
              <LocalTextInputGrow
                placeholder="代表取締役"
                value={job?.ecEmployerJaRepresentativeTitle ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerJaRepresentativeTitle: val })}
              />
              <Text>/</Text>
              <Text>EN</Text>
              <LocalTextInputGrow
                placeholder="CEO"
                value={job?.ecEmployerEnRepresentativeTitle ?? ""}
                onTextChange={(val) => onChange?.({ ecEmployerEnRepresentativeTitle: val })}
              />
            </TransValFieldGroup>
          </FieldCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default CompanyInfoSection;
