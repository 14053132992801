import {
    ChangeEvent,
    FunctionComponent,
    InputHTMLAttributes,
    useEffect,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';

import Field from './Field';
import Row from './Row';
import Text from './Text';
import TextInput from './TextInput';

import { isValidPostalCode } from '../utils';
import { getAddressInformation } from '../apis/address';
import styled from 'styled-components';
import { AxiosError } from 'axios';

interface PostalCodeInputProps extends InputHTMLAttributes<HTMLInputElement> {
    error?: boolean;
    suppressErrorMessage?: boolean;
    searchOnFirstLoading?: boolean;
    clearAddrInfoWhenIncomplete?: boolean;
    onTextChange?: (value: string) => void;
    onPrefectureChange?: (val: {jaPrefecture: string, enPrefecture: string}) => void;
    onCityChange?: (val: {jaCity: string, enCity: string}) => void;
    onTownChange?: (val: {jaTown: string, enTown: string}) => void;
    onPostalCodeAddressIdChange?: (id: string) => void;
}

enum AutoFillState {
    Inactive,
    Success,
    Invalid,
    SystemError
}

const LocalTextInput = styled(TextInput)`
    width: 170px;
`;

const LocalRow = styled(Row)`
    align-items: center;
`

const SuccessText = styled(Text)`
    color: #40BF6E;
`;

const ErrorText = styled(Text)`
    color: #E93232;
`;

const PostalCodeInput: FunctionComponent<PostalCodeInputProps> = ({
    value,
    clearAddrInfoWhenIncomplete = false,
    error,
    suppressErrorMessage,
    searchOnFirstLoading,
    onTextChange,
    onPrefectureChange,
    onCityChange,
    onTownChange,
    onPostalCodeAddressIdChange,
    ...props
}) => {
    const [autoFillStatus, setAutoFillStatus] = useState<AutoFillState>(AutoFillState.Inactive);
    const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
    const [didErrorOccur, setDidErrorOccur] = useState<boolean>(false);
    const { t } = useTranslation();
    const POSTAL_CODE_MAX = 7;
    const sanitizedVal = value?.toString().replaceAll('-', '') ?? '';
    
    //Equal or less than 7 digits
    //and only numbers
    const allowUpdating = (value: string) => 
        value.length <= POSTAL_CODE_MAX && !isNaN(Number(value));
        
    const onBlur = () => {
        setAutoFillStatus(AutoFillState.Inactive);
        setDidErrorOccur(false);
    }
    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const postalCode = event.target.value;
        setIsFirstLoading(false);

        if (allowUpdating(postalCode)) {
            onTextChange?.(postalCode);
            setAutoFillStatus(AutoFillState.Inactive);
            setDidErrorOccur(false);

            if (clearAddrInfoWhenIncomplete) {
                onPrefectureChange?.({jaPrefecture: '', enPrefecture: ''});
                onCityChange?.({jaCity: '', enCity: ''});
                onTownChange?.({jaTown: '', enTown: ''});
                onPostalCodeAddressIdChange?.('');
            }
        }
    };

    useEffect(() => {
        (async () => {
            if (!searchOnFirstLoading && isFirstLoading)
                return;
            
            if (!isValidPostalCode(sanitizedVal))
                return;

            try {
                const response = await getAddressInformation(
                    value as string,
                );
                const {
                    jaPrefecture,
                    enPrefecture,
                    jaCity,
                    enCity,
                    jaTown,
                    enTown,
                    id
                } = response;

                onPrefectureChange?.({jaPrefecture, enPrefecture});
                onCityChange?.({jaCity, enCity});
                onTownChange?.({jaTown, enTown});
                onPostalCodeAddressIdChange?.(id);
                setAutoFillStatus(AutoFillState.Success);

            } catch (e) {
                if (e instanceof AxiosError) {
                    if (e.response && e.response.status === 404) 
                        setAutoFillStatus(AutoFillState.Invalid);
                    else 
                        setAutoFillStatus(AutoFillState.SystemError);
                    
                    setDidErrorOccur(true);
                }
            }
            
        })();
    }, [sanitizedVal])

    const AutoFillMessage = ({ status } : { status: AutoFillState }) => {
        if (status === AutoFillState.Success)
            return (
                <LocalRow>
                    <img alt="check" src="/images/icon-check.svg" />
                    <SuccessText>{t('job.get_address_from_postal_code_sucess')}</SuccessText>
                </LocalRow>
            )

        if (status === AutoFillState.Invalid)
            return (
                <LocalRow>
                    <img alt="cross" src="/images/icon-cross.svg" />
                    <ErrorText>{t('job.get_address_from_postal_code_invalid')}</ErrorText>
                </LocalRow>
            )

        if (status === AutoFillState.SystemError)
            return (
                <LocalRow>
                    <img alt="cross" src="/images/icon-cross.svg" />
                    <ErrorText>{t('job.get_address_from_postal_code_system_error')}</ErrorText>
                </LocalRow>
            )

        return null
    }


    return (  
        <Field>
            <LocalTextInput
                value={sanitizedVal}
                style={ didErrorOccur? { borderColor : "#E93232" } : {} }
                placeholder="1080075"
                onChange={handleChange}
                onBlur={onBlur}
                error={error}
                maxLength={POSTAL_CODE_MAX}
                {...props}
            />
            <AutoFillMessage status={autoFillStatus}/>
            {   error && !suppressErrorMessage &&
                <Text error={true} style={{ fontSize: 12 }}>{t('core.input_prompt.text_field')}</Text>
            }
        </Field>
        
    );
};

export default PostalCodeInput;