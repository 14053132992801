import { Company } from "../../../core/types/api";
import { Job } from "../../types/api";

type Data = {
  company?: Company | null;
}

export const prepareInitializedJobFormData = (data: Data): Partial<Job> => {
  const initVals: Partial<Job> = {};
  const { company } = data;
  const employer = company?.employer!;

  if (!company)
    return {};

  /* For 企業情報 / Company information section */
  
  /* 会社/企業名 / Company name */
  initVals.ecEmployerJaOrganizationName = company.jaName ?? '';
  initVals.ecEmployerEnOrganizationName = company.enName ?? '';

  /* 郵便番号 / Postal code */
  initVals.ecEmployerPostalCode = company.postalCode ?? '';

  /* 都道府県 / Prefecture */
  initVals.ecEmployerJaPrefecture = company.jaPrefecture ?? '';
  initVals.ecEmployerEnPrefecture = company.enPrefecture ?? '';

  /* 市区 / City */
  initVals.ecEmployerJaCityWard = company.jaCityWard ?? '';
  initVals.ecEmployerEnCityWard = company.enCityWard ?? '';

  /* 町村 / Town */
  initVals.ecEmployerJaTown = company.jaTown ?? '';
  initVals.ecEmployerEnTown = company.enTown ?? '';

  /* 番地 / Street address */
  initVals.ecEmployerJaAddressNumber = company.jaAddressNumber ?? '';
  initVals.ecEmployerEnAddressNumber = company.enAddressNumber ?? '';

  /* 建物名等 / Building name */
  initVals.ecEmployerJaBuilding = company.jaBuildingName ?? '';
  initVals.ecEmployerEnBuilding = company.enBuildingName ?? '';

  /* 電話番号 / Phone number */ 
  initVals.ecEmployerPhoneNumber = company.phoneNumber ?? '';

  /* 代表者名 / Representative name */
  initVals.ecEmployerJaRepresentativeName = company.jaRepresentativeName ?? '';
  initVals.ecEmployerEnRepresentativeName = company.enRepresentativeName ?? '';

  /* 代表者役職 / Representative position */
  initVals.ecEmployerJaRepresentativeTitle = company.jaRepresentativeTitle ?? '';
  initVals.ecEmployerEnRepresentativeTitle = company.enRepresentativeTitle ?? '';


  /* For 就業に関する情報 / Employment information section */
  /* 事務所名 */
  initVals.ecPlaceOfEmploymentJaOfficeName = company.jaName ?? '';
  initVals.ecPlaceOfEmploymentEnOfficeName = company.enName ?? '';

  /* 郵便番号 / Postal code */
  initVals.postalCode = company.postalCode ?? '';
  initVals.ecPlaceOfEmploymentPostalCode = company.postalCode ?? '';

  /* 都道府県 / Prefecture */
  initVals.jaPrefecture = company.jaPrefecture ?? '';
  initVals.enPrefecture = company.enPrefecture ?? '';
  initVals.ecPlaceOfEmploymentJaPrefecture = company.jaPrefecture ?? '';
  initVals.ecPlaceOfEmploymentEnPrefecture = company.enPrefecture ?? '';

  /* 市区 / City */
  initVals.jaCity = company.jaCityWard ?? '';
  initVals.enCity = company.enCityWard ?? '';
  initVals.ecPlaceOfEmploymentJaCityWard = company.jaCityWard ?? '';
  initVals.ecPlaceOfEmploymentEnCityWard = company.enCityWard ?? '';

  /* 町村 / Town */
  initVals.jaTown = company.jaTown ?? '';
  initVals.enTown = company.enTown ?? '';
  initVals.ecPlaceOfEmploymentJaTown = company.jaTown ?? '';
  initVals.ecPlaceOfEmploymentEnTown = company.enTown ?? '';

  /* 番地 / Street address */
  initVals.jaAddressNumber = company.jaAddressNumber ?? '';
  initVals.enAddressNumber = company.enAddressNumber ?? '';
  initVals.ecPlaceOfEmploymentJaAddressNumber = company.jaAddressNumber ?? '';
  initVals.ecPlaceOfEmploymentEnAddressNumber = company.enAddressNumber ?? '';

  /* 建物名等 / Building name */
  initVals.jaBuildingName = company.jaBuildingName ?? '';
  initVals.enBuildingName = company.enBuildingName ?? '';
  initVals.ecPlaceOfEmploymentJaBuilding = company.jaBuildingName ?? '';
  initVals.ecPlaceOfEmploymentEnBuilding = company.enBuildingName ?? '';

  /* 電話番号 / Phone number */
  initVals.ecPlaceOfEmploymentPhoneNumber = company.phoneNumber ?? '';

  /* 賞与 / Bonus */
  initVals.bonus = employer.bonus;

  
  /* For 給与 / Salary section */
  /* 締切日/支払日1 */
  initVals.ecCompensationPayrollCutOffDate_1 = employer.compensationPayrollCutOffDate1;
  initVals.ecCompensationPayrollDate_1 = employer.compensationPayrollDate1;

  /* 締切日/支払日2 */
  initVals.ecCompensationPayrollCutOffDate_2 = employer.compensationPayrollCutOffDate2;
  initVals.ecCompensationPayrollDate_2 = employer.compensationPayrollDate2;


  return initVals;
}